var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vld-parent" },
    [
      _c("loading", {
        attrs: {
          active: _vm.isBusy,
          "can-cancel": true,
          "is-full-page": _vm.fullPage
        },
        on: {
          "update:active": function($event) {
            _vm.isBusy = $event
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "container-fluid content vue-droppable",
          attrs: { id: "planner" }
        },
        [
          _c("vue-headful", {
            attrs: {
              title: _vm.$target.toUpperCase() + " Office | Car Planner",
              description: "Car Planner"
            }
          }),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col col-2" },
              [
                _c("b-form-select", {
                  attrs: { options: _vm.timeframelist },
                  model: {
                    value: _vm.timeframe,
                    callback: function($$v) {
                      _vm.timeframe = $$v
                    },
                    expression: "timeframe"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col col-10" },
              [
                _c("b-form-select", {
                  attrs: { options: _vm.periodlist },
                  model: {
                    value: _vm.period,
                    callback: function($$v) {
                      _vm.period = $$v
                    },
                    expression: "period"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "wrapper noselect" }, [
            _c(
              "div",
              { staticClass: "grid", staticStyle: { "margin-top": "10px" } },
              [
                _c(
                  "div",
                  { staticClass: "tr" },
                  [
                    _c("div", { staticClass: "td" }, [
                      _c(
                        "div",
                        {
                          staticClass: "abstandhalter",
                          staticStyle: { width: "138px" }
                        },
                        [_c("h1", { ref: "neutralizer" }, [_vm._v("Planner")])]
                      )
                    ]),
                    _vm._l(_vm.timespan, function(ts, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          ref: "times",
                          refInFor: true,
                          staticClass: "td date"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "abstandhalter",
                              staticStyle: { width: "138px" }
                            },
                            [_vm._v(" " + _vm._s(ts.text) + " ")]
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._l(_vm.cars, function(row, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      ref: "cars",
                      refInFor: true,
                      staticClass: "tr",
                      attrs: {
                        id: "row_" + row.CAR_ID,
                        "data-id": row.CAR_ID,
                        height: "24px"
                      }
                    },
                    [
                      _c("div", { class: "td class_" + row.CAR_CLASS }, [
                        _c(
                          "div",
                          {
                            staticClass: "abstandhalter",
                            staticStyle: { width: "138px" }
                          },
                          [
                            _c("h4", [_vm._v(_vm._s(row.CAR_PLATE))]),
                            _c("div", { staticClass: "model" }, [
                              _vm._v(
                                _vm._s(row.CAR_MAKE) +
                                  " " +
                                  _vm._s(row.CAR_MODEL)
                              )
                            ])
                          ]
                        )
                      ]),
                      _vm._l(_vm.timespan, function(ts, idx) {
                        return _c("div", { key: idx, staticClass: "td date" })
                      })
                    ],
                    2
                  )
                })
              ],
              2
            )
          ]),
          _vm._l(_vm.bookings, function(booking, ind) {
            return _c(
              "vue-draggable-resizable",
              {
                key: booking.key,
                ref: "bookings",
                refInFor: true,
                class: [
                  "noselect",
                  "movableblock",
                  "status" + booking.BO_STATUS
                ],
                style:
                  "left:" +
                  booking.left +
                  "px;top:" +
                  booking.top +
                  "px;width:" +
                  booking.width +
                  "px",
                attrs: {
                  w: booking.width || 300,
                  h: 50,
                  z: ind + 500,
                  id: "booking_" + booking.BO_ID,
                  "data-index": ind,
                  axis: "y",
                  grid: [10, 52],
                  onDrag: _vm.onDragging,
                  "class-name-dragging": "dragger-active"
                },
                on: {
                  activated: function($event) {
                    return _vm.onCheckForAction(booking, ind)
                  },
                  deactivated: function($event) {
                    return _vm.onDeActivate(booking.BO_ID, ind)
                  },
                  dragstop: function(left, top) {
                    return _vm.onDragStop(booking.BO_ID, left, top)
                  }
                }
              },
              [
                booking.CL_VOUCHERS > 0
                  ? _c("span", { staticClass: "vouchercount" }, [
                      _vm._v(_vm._s(booking.CL_VOUCHERS))
                    ])
                  : _vm._e(),
                _vm._v(" " + _vm._s(booking.BO_NAME) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(booking.REMARKS) + " ")
              ]
            )
          }),
          _vm._l(_vm.bookings, function(booking, ind) {
            return _c(
              "b-tooltip",
              {
                key: ind,
                attrs: { target: "booking_" + booking.BO_ID, triggers: "hover" }
              },
              [
                _c("span", { staticClass: "cartip" }, [
                  _c(
                    "table",
                    { staticStyle: { width: "100%", "margin-bottom": "4px" } },
                    [
                      _c("tr", [
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass: "statuschanger indicator-1",
                              on: {
                                click: function($event) {
                                  return _vm.setStatus(booking, 1)
                                }
                              }
                            },
                            [_vm._v(" G ")]
                          )
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/tickets/details/" + booking.BO_TICKETID,
                                target: "new"
                              }
                            },
                            [_vm._v(_vm._s(booking.BO_TICKETID))]
                          ),
                          _vm._v(" - " + _vm._s(booking.BO_PRICE_SUM) + " € ")
                        ]),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "statuschanger indicator-4",
                              on: {
                                click: function($event) {
                                  return _vm.setStatus(booking, 4)
                                }
                              }
                            },
                            [_vm._v(" R ")]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(
                    " " + _vm._s(_vm._f("shortenCar")(booking.BO_CAR)) + " "
                  ),
                  _c("br"),
                  _vm._v(" " + _vm._s(booking.period) + " ")
                ])
              ]
            )
          }),
          _c("PlannerDetail", {
            attrs: {
              boid: parseInt(this.BookingInfoId),
              showBooking: this.showBookingForm
            }
          }),
          _c("BookingSingle", {
            attrs: { BO_ID: this.BookingFormId, TICKET: this.currentTicket }
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }