import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import dayjs from "dayjs";
import "@/assets/scss/tickets.scss";
import ticketSource from "@/lib/backend/tickets";
import ClientSingle from "@/views/ClientSingle";
import TicketFilters from "@/components/TicketFilters";
import BookingSingle from "@/views/BookingSingle";
import tableFields from "@/lib/misc";
import { BPaginationNav, BSpinner } from "bootstrap-vue";
Vue.component("b-pagination-nav", BPaginationNav);
Vue.component("b-spinner", BSpinner);

const initialDate = dayjs()
	.subtract(1, "year")
	.format("YYYY-MM-DD");

export default {
	components: {
		ClientSingle,
		TicketFilters,
		BookingSingle
	},
	data() {
		return {
			initialDate: initialDate,
			filters: {},
			perPage: 20,
			statusChanger: 0,
			userChanger: 0,
			currentPage: 1,
			currentClientId: 0,
			currentBookingId: null,
			currentTicket: null,
			currentAssigned: null,
			currentStatus: null,
			numrows: 0,
			statusOptions: tableFields.statusOptions,
			fields: tableFields.ticketListFields
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token,
			users: state => state.helpers.users,
			tickets: state => state.tickets.tickets,
			isBusy: state => state.tickets.isBusy
		}),
		totalPages: function() {
			// `this` points to the vm instance
			return this.numrows == 0 ? 10 : Math.ceil(this.numrows / this.perPage);
		},
		filteredData: function() {
			if (this.filters == {}) {
				return this.tickets;
			} // no filtering
			if (
				Object.keys(this.filters).length === 0 &&
				this.filters.constructor === Object
			) {
				return this.tickets;
			}
			// console.log("There are filters. they are ", typeof(this.filters))
			var items = this.tickets;
			let that = this;
			if (this.filters.status) {
				items = items.filter(function(el) {
					return el.TI_STATUS == that.filters.status;
				});
			}
			if (this.filters.department) {
				items = items.filter(function(el) {
					return el.TI_DEPT == that.filters.department;
				});
			}
			if (this.filters.voucher) {
				items = items.filter(function(el) {
					return el.HAS_VOUCHER == that.filters.voucher;
				});
			}
			if (this.filters.freetext) {
				let regex = new RegExp(that.filters.freetext, "gi");
				items = items.filter(function(el) {
					return (
						el.TI_SUBJECT.match(regex) ||
						el.CL_EMAIL.match(regex) ||
						el.CL_FIRSTNAME.match(regex) ||
						el.CL_LASTNAME.match(regex)
					);
				});
			}
			if (this.filters.contains) {
				let regex = new RegExp(that.filters.contains, "gi");
				items = items.filter(function(el) {
					return (
						el.CL_EMAIL.match(regex) ||
						el.CL_FIRSTNAME.match(regex) ||
						el.CL_LASTNAME.match(regex) ||
						el.TI_ID.match(regex) ||
						el.TI_CLIENTID.match(regex)
					);
				});
			}
			if (this.filters.subject) {
				let regex = new RegExp(that.filters.subject, "gi");
				items = items.filter(function(el) {
					return el.TI_SUBJECT.match(regex);
				});
			}
			if (this.filters.from) {
				let regex = new RegExp(that.filters.from, "gi");
				items = items.filter(function(el) {
					return (
						el.CL_EMAIL.match(regex) ||
						el.CL_FIRSTNAME.match(regex) ||
						el.CL_LASTNAME.match(regex)
					);
				});
			}
			if (this.filters.fromdate) {
				let fromstamp = dayjs(this.filters.fromdate).unix();
				items = items.filter(function(el) {
					return dayjs(el.BO_STOPD).unix() >= fromstamp;
				});
			}
			if (this.filters.todate) {
				let tostamp = dayjs(this.filters.todate).unix();
				items = items.filter(function(el) {
					return dayjs(el.BO_STARTD).unix() <= tostamp;
				});
			}
			if (this.filters.messagedate) {
				let startd = dayjs(this.filters.messagedate).unix();
				let endd = dayjs(this.filters.messagedate)
					.add(1, "month")
					.unix();
				items = items.filter(function(el) {
					return (
						dayjs(el.TI_LASTMSG).unix() >= startd &&
						dayjs(el.TI_LASTMSG).unix() < endd
					);
				});
			}
			if (this.filters.clid) {
				items = items.filter(function(el) {
					return el.TI_CLIENTID == that.filters.clid;
				});
			}

			this.$store.dispatch("TICKETS_SETFILTER", this.filters);

			this.numrows = items.length;
			return items;
		}
	},

	watch: {
		filters: {
			handler() {
				for (let k in this.filters) {
					if (
						this.filters[k] == "undefined" ||
						typeof this.filters[k] == "undefined"
					) {
						delete this.filters[k];
					}
				}
			},
			deep: true
		}
	},
	filters: {
		shortenDept: function(value) {
			return value.replace(/IDrive /, "");
		}
	},

	created: function() {
		// IF FORCE THEN USE THIS
		// this.$store.dispatch('TICKETS_REQUEST', {force: true})
		this.$store.dispatch("TICKETS_REQUEST");
		this.$store.dispatch("HELPERS_USERS");
	},

	mounted: function() {
		if (localStorage.getItem("ticket_filters")) {
			// console.log("we still got filters: ", JSON.parse(localStorage.getItem("ticket_filters")))
			this.filters = JSON.parse(localStorage.getItem("ticket_filters"));
		} else {
			this.filters = { status: "open" };
		}
		if (this.$route.params.page) {
			this.currentPage = this.$route.params.page;
		}
		if (this.$route.params.client) {
			this.filters.clid = parseInt(this.$route.params.client);
		}
	},
	methods: {
		linkGen(pageNum) {
			return { path: `/tickets/page/${pageNum}` };
		},

		IdBackgroundSelect(status, answered) {
			if (status == "open") return "open";
			if (status == "waiting") return "waiting";
			else if (status == "closed") return "closed";
			else if (answered == "0") return "unread";
			return "";
		},
		setStatusChanger(value) {
			this.statusChanger = value.TI_ID;
			this.currentStatus = value;
		},
		setUserChanger(value) {
			this.userChanger = value.TI_ID;
			this.currentAssigned = value.TI_ASSIGNEDTO;
		},
		updateStatus(value) {
			// console.log("The status for ", this.statusChanger, " is now ", value)
			ticketSource
				.setStatus(this.statusChanger, value)
				// eslint-disable-next-line no-unused-vars
				.then(res => {
					this.$store.dispatch("TICKETS_CHANGE", {
						TI_ID: this.statusChanger,
						field: "TI_STATUS",
						value: value
					});
					this.statusChanger = 0;
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		updateAssignedUser(value, idx) {
			// console.log(value + "!" + this.tickets[idx].TI_ASSIGNEDTO)
			ticketSource
				.setAssignedUser(this.userChanger, value)
				.then(this.setUserName(this.userChanger, value, idx))
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		setUserName(tiid, userid, idx) {
			this.userChanger = 0;
			let obj = this.users.find(o => o.value === userid);
			if (!obj || !obj.text) return false;
			this.$store.dispatch("TICKETS_SET_USER", {
				idx: idx,
				USR_LOGIN: obj.text,
				TI_ASSIGNEDTO: userid
			});
			this.$toastr.Add({
				title: "New User attached", // Toast Title
				msg: "",

				position: "toast-top-center",
				type: "success"
			});
		},
		setClientId(id) {
			this.currentClientId = id;
		},
		showBookingSingle(bo_id, ticket) {
			console.log("showing single for ", bo_id);
			this.currentBookingId = bo_id;
			this.currentTicket = ticket;
			this.$bvModal.show("bookingsingle");
		},
		resetFilters() {
			this.filters = {};
			this.$store.dispatch("TICKETS_CLEARFILTER");
		},
		preDelete(ticket) {
			let data = { TI_ID: ticket.TI_ID };
			let text = "Are you sure?";
			if (ticket.HAS_VOUCHER == "y") {
				text +=
					"\n- this will delete the voucher connected with this ticket (" +
					ticket.HAS_VOUCHER +
					")";
				data["hasvouchers"] = true;
			}
			if (confirm(text)) {
				ticketSource.deleteTicket(data).then(res => {
					this.$toastr.Add({
						title: "Ticket deleted", // Toast Title
						msg: res.data.Message,
						position: "toast-top-center",
						type: "success"
					});

					this.$store.dispatch("TICKETS_REMOVE_ENTRY", ticket.TI_ID);
					// console.log("Na dann wech!");
				});
			} else {
				// console.log("Na dann eben nicht");
			}
		}
	}
};
