/* eslint-disable no-unused-vars */
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import dayjs from "dayjs";
import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import "@/assets/scss/planner.scss";
import PlannerSource from "@/lib/backend/planner";
import TicketSource from "@/lib/backend/tickets";
import BookingSource from "@/lib/backend/bookings";
import PlannerDetail from "@/components/PlannerDetail";
import BookingSingle from "@/views/BookingSingle";
import tableFields from "@/lib/misc";
import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.min.css";
import { BSpinner, BTooltip } from "bootstrap-vue";
Vue.component("b-spinner", BSpinner);
Vue.component("b-tooltip", BTooltip);
Vue.component("vue-draggable-resizable", VueDraggableResizable);

export default {
	components: {
		PlannerDetail,
		BookingSingle,
		Loading
	},

	watch: {
		period: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.setGrid(this.timeframe);
			},
			deep: true
		},
		timeframe: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.setGrid(this.timeframe);
			},
			deep: true
		}
	},

	filters: {
		shortenCar: function(str) {
			let n = 32;
			return str.length > n ? str.substr(0, n - 1) + "..." : str;
		}
	},

	data() {
		return {
			spanwidth: 140, // the width of one timespan div
			period: "week",
			timespan: [],
			bookings: [],
			pixelfactor: 0,
			DEP_ID: 1,
			timeframe: "current",
			fields: tableFields.MailingListFields,
			currentZ: 0,
			startposition: { x: 0, y: 0 },
			BookingInfoId: 0,
			BookingFormId: 0,
			currentTicket: {},
			isBusy: true,
			periodlist: [
				{ value: "week", text: "Week" },
				{ value: "twodays", text: "2 Days" },
				{ value: "day", text: "Day" }
			],
			timeframelist: [
				{ value: "current", text: "Current" },
				{ value: "past", text: "From 01-01" }
			],
			fullPage: true
		};
	},
	computed: {
		...mapState({
			cars: state => state.planner.cars
			// isBusy: state => state.planner.isBusy
		})
	},

	beforeCreate: function() {
		this.$store.dispatch("HELPERS_NAVTOGGLE", { force: "0" });
		document.body.className = "planner";
	},

	created: function() {},

	mounted: function() {
		this.setGrid(this.timeframe);

		if (this.$route.params.page) {
			this.currentPage = this.$route.params.page;
		}
	},
	methods: {
		setGrid(rahmen) {
			this.bookings = [];
			this.isBusy = true;

			let initialDate =
				rahmen == "current"
					? dayjs().subtract(1, "month")
					: dayjs().startOf("year");
			let initialStamp =
				rahmen == "current"
					? dayjs()
							.subtract(1, "month")
							.unix()
					: dayjs()
							.startOf("year")
							.unix();
			let endDate = initialDate.add(12, "month");
			let endStamp = initialDate.add(12, "month").unix();
			this.timespan = [];

			if (this.period == "week") {
				while (initialDate < endDate) {
					initialDate = initialDate
						.hour(0)
						.minute(0)
						.second(0);
					let startd = initialDate.format("DD.MM.");
					let startstamp = initialDate.unix();
					initialDate = initialDate.add(6, "day");
					initialDate = initialDate
						.hour(23)
						.minute(59)
						.second(59);
					let stopd = initialDate.format("DD.MM.YY");
					initialDate = initialDate.add(1, "hour");
					initialDate = initialDate
						.hour(0)
						.minute(0)
						.second(0);
					let stopstamp = initialDate.unix();
					this.timespan.push({
						startstamp: startstamp,
						stopstamp: stopstamp,
						text: startd + " - " + stopd
					});
					//** */ find the factor for calculating the pixel width of booking blocks
					this.pixelfactor = this.spanwidth / (stopstamp - startstamp);
					// console.log("Factor is ", this.pixelfactor);
				}
			} else if (this.period == "day") {
				while (initialDate < endDate) {
					initialDate = initialDate
						.hour(0)
						.minute(0)
						.second(0);
					let startd = initialDate.format("DD.MM.");
					let startstamp = initialDate.unix();
					initialDate = initialDate.add(23, "hour");
					initialDate = initialDate
						.hour(23)
						.minute(59)
						.second(59);
					let stopd = initialDate.format("DD.MM.YY");
					initialDate = initialDate.add(1, "hour");
					initialDate = initialDate
						.hour(0)
						.minute(0)
						.second(0);
					let stopstamp = initialDate.unix();
					this.timespan.push({
						startstamp: startstamp,
						stopstamp: stopstamp,
						text: startd
					});
					//** */ find the factor for calculating the pixel width of booking blocks
					this.pixelfactor = this.spanwidth / (stopstamp - startstamp);
					// console.log("Factor is ", this.pixelfactor);
				}
			} else if (this.period == "twodays") {
				while (initialDate < endDate) {
					initialDate = initialDate
						.hour(0)
						.minute(0)
						.second(0);
					let startd = initialDate.format("DD.MM.");
					let startstamp = initialDate.unix();
					initialDate = initialDate.add(47, "hour");
					initialDate = initialDate
						.hour(23)
						.minute(59)
						.second(59);
					let stopd = initialDate.format("DD.MM.YY");
					initialDate = initialDate.add(1, "hour");
					initialDate = initialDate
						.hour(0)
						.minute(0)
						.second(0);
					let stopstamp = initialDate.unix();
					this.timespan.push({
						startstamp: startstamp,
						stopstamp: stopstamp,
						text: startd + " - " + stopd
					});
					//** */ find the factor for calculating the pixel width of booking blocks
					this.pixelfactor = this.spanwidth / (stopstamp - startstamp);
				}
			}
			this.$store.dispatch("CARS_REQUEST", { force: true }).then(() => {
				this.$nextTick(() => {
					this.getBookings(this.DEP_ID, initialStamp, endStamp);
					for (var value of this.cars) {
						// console.log(value);
					}
					window.scrollTo(0, 0);
				});
			});
		},

		getBookings(DEP_ID, startdate, stopdate) {
			PlannerSource.getBookings(DEP_ID, startdate, stopdate)
				.then(res => {
					this.bookings = res.data.bookings;
					// console.log(this.bookings)
					for (let i = 0; i < this.bookings.length; i++) {
						// Get the position of each booking
						let b_top = this.findBookingTop(this.bookings[i].BO_CARID);
						// console.log(startdate + " vs " + this.bookings[i].startstamp, ", " + stopdate);
						let leftlimit = this.bookings[i].startstamp;
						if (this.bookings[i].startstamp < startdate) {
							leftlimit = dayjs()
								.subtract(1, "month")
								.hour(0)
								.minute(0)
								.second(0)
								.unix();
						}
						let b_dimensions = this.findBookingLeft(
							leftlimit,
							this.bookings[i].stopstamp
						);
						this.bookings[i]["left"] = b_dimensions.left;
						this.bookings[i]["width"] = b_dimensions.width;
						this.bookings[i]["top"] = b_top;
						this.bookings[i]["statusclass"] =
							"status" + this.bookings[i].BO_STATUS;
						this.bookings[i]["key"] = "booking_" + this.bookings[i].BO_ID;
						this.bookings[i]["conflict"] = "";
					}
					// console.log("found ", this.bookings.length, " bookings")
					this.isBusy = false;
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.e(error, "Oooops.");
				});
		},

		findBookingTop(carid) {
			let obj = this.cars.findIndex(o => o.CAR_ID === carid);
			if (obj >= 0) {
				// console.log("Yeah the car idx is ", obj)
				let top = this.$refs.cars[obj].getBoundingClientRect().top;
				// console.log("found dings at ", top)
				return top;
			} else {
				// console.warn("No car")
				return false;
			}
		},

		findBookingLeft(start, stop) {
			// console.log("TESTTT ", this.timespan[3].startstamp, " - ", this.timespan[3].stopstamp)
			// console.log("TESTTT ", start)
			let tst = this.timespan.findIndex(
				t => parseInt(t.startstamp) <= start && start <= parseInt(t.stopstamp)
			);
			if (tst >= 0) {
				// console.log("Yeah the time idx for " , start, " is ", tst)
				/**
				 * For the sake of layout we assume the bookings are from noon to noon.
				 * this way we can have two ending/starting the same day
				 */
				let startnoon = 0;
				let endnoon = 0;
				// if (this.period == "week") {
				// 	startnoon = dayjs
				// 		.unix(start)
				// 		.hour(12)
				// 		.minute(0)
				// 		.unix();
				// 	endnoon = dayjs
				// 		.unix(stop)
				// 		.hour(12)
				// 		.minute(0)
				// 		.unix();
				// } else if (this.period == "day" || this.period == "twodays") {
				startnoon = start;
				endnoon = stop;
				// }
				// console.log(start, "-", stop)
				// console.log(startnoon, " to ", endnoon)
				let bookleft =
					this.$refs.times[tst].getBoundingClientRect().left +
					(startnoon - this.timespan[tst].startstamp) * this.pixelfactor;
				let bookwidth = (endnoon - startnoon) * this.pixelfactor;
				return { left: bookleft, width: bookwidth };
			} else {
				// console.log("No time at " + start)
				return false;
			}
		},
		onDragging: function(x, y) {
			if (!event.shiftKey) {
				console.log("NO shift!");
				return false;
			}
		},

		onDragStop: function(BOID, x, y) {
			let movedbooking = this.bookings.find(t => t.BO_ID == BOID);
			movedbooking.key = "booking_" + BOID;
			movedbooking.conflict = "";
			let position = document.querySelector("#booking_" + BOID);
			// const stopleft= position.getBoundingClientRect().left
			position.classList.remove("conflict");
			const stoptop = position.getBoundingClientRect().top;
			const stopleft = position.getBoundingClientRect().left;
			const stopright = position.getBoundingClientRect().right;
			const stopbottom = position.getBoundingClientRect().bottom;

			// Get start and stop date
			console.log("This booking is ", movedbooking);

			/*
			 * Check for collision
			 */
			let blocks = document.querySelectorAll(".movableblock");
			// console.log("check against ", blocks.length, " entries")
			for (let i = 0; i < blocks.length; i++) {
				let bl = blocks[i];
				let bl_id = bl.id.replace(/booking_/, "");
				if (parseInt(bl_id) == BOID) continue;

				// From https://dba.stackexchange.com/questions/111426/detect-if-two-time-periods-overlap
				// @Start < EndDate and @End > StartDate

				if (
					stopleft < bl.getBoundingClientRect().right &&
					stopright > bl.getBoundingClientRect().left &&
					stoptop >= bl.getBoundingClientRect().top &&
					stoptop <= bl.getBoundingClientRect().bottom
				) {
					// console.log(
					// 	"COLLISION with " + bl.id + " ! on ",
					// 	stopleft,
					// 	" and ",
					// 	stoptop
					// );
					event.target.style.border = "4px solid red";
					return false;
				}
			}
			event.target.style.border = "1px solid #777";
			// this.$forceUpdate();

			let rows = document.querySelectorAll('div[class="tr"]');
			for (let i = 0; i < rows.length; i++) {
				let elem = rows[i];
				if (
					stoptop >= elem.getBoundingClientRect().top &&
					stoptop <= elem.getBoundingClientRect().bottom &&
					stopbottom <= elem.getBoundingClientRect().bottom &&
					stopbottom >= elem.getBoundingClientRect().top
				) {
					// console.log(
					// 	elem.id + " ist ein Hit, weil  top: ",
					// 	elem.getBoundingClientRect().top,
					// 	", bot:",
					// 	elem.getBoundingClientRect().bottom
					// );

					let carid = elem.id.replace(/row_/, "");
					/* Check collision by stamp */
					let samecarbookings = this.bookings.filter(
						t => t.BO_CARID == carid && t.BO_ID != movedbooking.BO_ID
					);
					if (samecarbookings && samecarbookings.length > 0) {
						for (let u = 0; u < samecarbookings.length; u++) {
							if (
								samecarbookings[u].startstamp < movedbooking.stopstamp &&
								movedbooking.startstamp < samecarbookings[u].stopstamp
							) {
								event.target.style.border = "4px solid red";
								return false;
							}
						}
					}
					event.target.style.border = "1px solid #777";

					if (carid && parseInt(carid) > 0) {
						PlannerSource.setCar(BOID, carid).then(res => {
							movedbooking.BO_CARID = carid;
						});
					}
				}
			}
		},

		onCheckForAction(booking, ind) {
			console.log("onCheckForAction: ", event);
			if (event.ctrlKey) {
				this.showBookingInfo(booking);
				this.onActivate(booking, ind);
				this.$refs.neutralizer.click();
				// console.log("neutralizer ", this.$refs.neutralizer.innerHTML)
				this.onDeActivate(booking, ind);
			} else if (event.shiftKey) {
				console.log("shift!");
				this.onActivate(booking, ind);
			} else {
				return false;
			}
		},

		onActivate: function(booking, index) {
			let position = document.querySelector("#booking_" + booking.BO_ID);
			this.startposition = {
				x: position.getBoundingClientRect().left,
				y: position.getBoundingClientRect().top
			};
			this.currentZ = this.$refs.bookings[index].zIndex;
			this.$refs.bookings[index].zIndex = 9999;
		},

		onDeActivate: function(BOID, index) {
			console.log("DeActivate ", BOID);
			this.$refs.bookings[index].zIndex = this.currentZ;
		},

		showBookingInfo: function(booking) {
			console.log("info with ", booking);
			this.BookingInfoId = booking.BO_ID;
			this.currentBooking = booking;
			this.$bvModal.show("plannerdetail");
		},

		showBookingForm: function(BO_ID, BO_TICKETID) {
			TicketSource.getTicket(BO_TICKETID)
				.then(res => {
					this.currentTicket = res.data;
					this.BookingFormId = BO_ID;
					this.$bvModal.hide("plannerdetail");
					console.log("show booking with");
					console.log(this.currentTicket);
					this.$nextTick(() => {
						this.$bvModal.show("bookingsingle");
					});
				})
				.catch(error => {
					this.$toastr.e(error, "Could not get Ticket data");
				});
		},

		setStatus(booking, status) {
			console.log("Set status of" + booking.BO_ID + " to " + status);
			let item = document.querySelector("#booking_" + booking.BO_ID);
			let cl = item.className;
			console.log("Has classes " + cl);
			BookingSource.updateBooking({
				BO_ID: booking.BO_ID,
				BO_TICKETID: booking.BO_TICKETID,
				BO_STATUS: status
			})
				.then(res => {
					booking.BO_STATUS = status;
					item.className =
						"draggable resizable vdr noselect movableblock status" + status;
					console.log(res);
				})
				.catch(error => {
					this.$toastr.e(error, "Could not set Booking status");
				});
		}
	}
};
