export default {
	seasons: {
		L: "Low",
		M: "Middle",
		H: "High"
	},
	classes: ["A", "B", "B+", "C", "D", "E", "F", "J", "X"],
	voucherOptions: [
		{ text: "all", value: null },
		{ text: "yes", value: "y" },
		{ text: "no", value: "n" }
	],
	statusOptions: [
		{ text: "---", value: null },
		{ text: "open", value: "open" },
		{ text: "closed", value: "closed" },
		{ text: "new", value: "new" },
		{ text: "answered", value: "answered" },
		{ text: "waiting", value: "waiting" },
		{ text: "later", value: "later" },
		{ text: "unpaid", value: "unpaid" }
	],
	bookingStatusOptions: [
		"Planned",
		"Running",
		"No flight details",
		"Blocked",
		"Done",
		"service",
		"Painter"
	],
	languages: ["nl", "en", "de"],
	messages: [
		{
			key: "MSG_ID",
			label: " ",
			sortable: true
		},
		{
			key: "MSG_CD",
			label: "Date",
			sortable: true
		},
		{
			key: "MSG_SUBJECT",
			label: "Subject",
			sortable: true
		},
		{
			key: "MSG_FROM",
			label: "FROM",
			sortable: true
		}
	],
	ticketListFields: [
		{
			key: "TI_ID",
			label: "Id",
			sortable: true
		},
		{
			key: "TI_STATUS",
			label: "Status",
			sortable: true
		},
		{
			key: "TI_LASTMSG",
			label: "Last Msg",
			tdClass: "smaller",
			sortable: true
		},

		{
			key: "DEP_NAME",
			label: "Dept.",
			sortable: true
		},

		{
			key: "CL_LASTNAME",
			label: "From",
			sortable: false
		},

		{
			key: "TI_SUBJECT",
			label: "Subject",
			sortable: false
		},
		{
			key: "TI_ASSIGNEDTO",
			label: "User",
			sortable: true
		},

		{
			key: "TI_LANG",
			label: "Lang",
			sortable: false
		},

		{
			key: "BO_STARTD",
			label: "Booking",
			sortable: true
		},

		{
			key: "MUELL",
			label: "X",
			sortable: false
		}
	],

	clientListFields: [
		{
			key: "CL_ID",
			label: "Id",
			sortable: true
		},
		{
			key: "CL_LASTNAME",
			label: "Last Name",
			tdClass: "smaller",
			sortable: true
		},

		{
			key: "CL_FIRSTNAME",
			label: "First Name",
			sortable: true
		},

		{
			key: "CL_EMAIL",
			label: "Email",
			sortable: true
		},

		{
			key: "CL_REGDATE",
			label: "Reg. Date",
			sortable: true
		},

		{
			key: "CL_TICKETS",
			label: "Tickets",
			sortable: true
		},

		{
			key: "CL_VOUCHERS",
			label: "Vouchers",
			sortable: true
		},

		{
			key: "MUELL",
			label: "Trash",
			sortable: false
		}
	],
	userListFields: [
		{
			key: "USR_ID",
			label: "Id",
			sortable: true
		},
		{
			key: "USR_NAME",
			label: "Name",
			tdClass: "smaller",
			sortable: true
		},
		{
			key: "USR_LOGIN",
			label: "Login",
			tdClass: "smaller",
			sortable: true
		},
		{
			key: "NEWPASSWD",
			label: "Password",
			tdClass: "smaller",
			sortable: false
		},
		{
			key: "USR_EMAIL",
			label: "Email",
			tdClass: "smaller",
			sortable: true
		},
		{
			key: "USR_STATUS",
			label: "Status",
			tdClass: "smaller",
			sortable: true
		},
		{
			key: "USR_DEPT",
			label: "Department",
			tdClass: "smaller",
			sortable: false
		},
		{
			key: "MUELL",
			label: "Edit",
			sortable: false
		},
		{
			key: "USR_HIDDEN",
			label: "Hidden",
			tdClass: "smaller",
			sortable: false
		}
	],
	MailingListFields: [
		{
			key: "MA_ID",
			label: "Id",
			sortable: true
		},
		{
			key: "DEP_NAME",
			label: "Department",
			sortable: true
		},
		{
			key: "MA_SUBJECT",
			label: "Subject",
			tdClass: "smaller",
			sortable: true
		},

		{
			key: "MA_LANG",
			label: "Language",
			tdClass: "smaller",
			sortable: true
		},

		{
			key: "MA_SENT",
			label: "Sent to",
			sortable: true
		},

		{
			key: "MUELL",
			label: "Trash",
			sortable: false
		}
	],

	userstatusarray: {
		"0": "No Access",
		"3": "Log Only",
		"1": "Editor",
		"2": "Administrator",
		"4": "Agent"
	},

	carlog_categories: [
		{ value: "1", text: "Versicherung" },
		{ value: "2", text: "Werkstatt" },
		{ value: "4", text: "Innenraum" },
		{ value: "5", text: "Motor" },
		{ value: "6", text: "Reifen" },
		{ value: "7", text: "Andere" }
	],

	carlog_statusarray: [
		{ value: "1", text: "To do" },
		{ value: "2", text: "in Arbeit" },
		{ value: "3", text: "Dringend" },
		{ value: "4", text: "Done" }
	],

	getSelecter(data) {
		let sel = [];
		for (let key in data) {
			sel.push({
				value: key,
				text: data[key]
			});
		}
		return sel;
	}
};
